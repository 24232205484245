<!--
  * 批量新增
-->
<template>
  <div>
    <!-- 选择记录类型 -->
    <div class="xzjllx" v-show="recordTypeList.length > 1">
      <span class="btnx">{{ $t("label.recordtypeselect") }}：</span>
      <el-select
        v-model="recordType"
        ref="selectval"
        size="mini"
        @change="getbatchadding"
        class="el-select"
      >
        <el-option
          v-for="item in recordTypeList"
          :key="item.name"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="al" ref="ulbox">
      <div ref="allUl">
        <!-- 表头数据 -->
        <ul class="listulFir" ref="firul">
          <li class="firstLi">
            {{ $t("label.ems.action") }}
          </li>
          <li
            class="secondLi"
            style="align-items: center"
            ref="oli"
            v-for="item in fieldlist"
            :key="item.id"
          >
            {{ item.label }}
          </li>
        </ul>
        <!-- 表格数据 -->
        <ul
          ref="ulist"
          class="listulSec"
          :key="num"
          v-for="(data, num) in dataList"
        >
          <li class="firstLi delBtn">
            <span v-if="dataList.length > 1" @click="deleteUl(num)">
              <!-- 删除 -->
              {{ $t("label_chatter_delete") }}
            </span>
          </li>
          <li
            class="secondLi"
            ref="olisec"
            style="position: relative"
            v-for="item in fieldlist"
            :key="item.entityFieldName"
          >
            <span
              class="requiredIdentification"
              v-show="item.required === '1' && item.readonly === '0'"
              >*</span
            >
            <div
              class="uploadFile"
              @click="openUpload(item, num)"
              v-if="
                item.type === 'file' && fileList[num][item.name].length === 0
              "
            >
              <i class="el-icon-circle-plus-outline"></i>
              <span>{{ $t("label.chat.file.upload.info") }}</span>
            </div>
            <div
              class="fileContent"
              v-if="
                item.type === 'file' && fileList[num][item.name].length !== 0
              "
            >
              <div
                class="fileItem"
                v-for="(fileItem, fileIndex) in fileList[num][item.name]"
                :key="fileIndex"
              >
                <div class="itemFir">
                  <div class="itemTitle">
                    <svg
                      style="width: 15px; height: 15px"
                      class="icon"
                      aria-hidden="true"
                    >
                      <use :href="allIcon[fileItem.type] || defaultIcon"></use>
                    </svg>
                    <div class="name">{{ fileItem.name }}</div>
                    <div class="houzhui">.{{ fileItem.type }}</div>
                  </div>
                  <!--  -->
                  <svg
                    @click="deleteFile(num, item.name, fileIndex)"
                    style="cursor: pointer; margin: 0px 4px"
                    class="icon"
                    aria-hidden="true"
                  >
                    <use href="#icon-system_delete_ic"></use>
                  </svg>
                </div>
                <div
                  class="itemSec"
                  v-if="
                    fileIndex === fileList[num][item.name].length - 1 &&
                    fileList[num][item.name].length !==
                      Number(item.defaultvalue)
                  "
                  @click="openUpload(item, num)"
                >
                  <i
                    style="width: 15px; height: 15px"
                    class="el-icon-circle-plus-outline"
                  ></i>
                  <span>{{ $t("label.chat.file.upload.info") }}</span>
                </div>
              </div>
            </div>
            <!-- 地理定位字段 -->
            <el-button
              class="icon big_map"
              aria-hidden="true"
              v-if="item.name === 'dldw'"
              type="primary"
              @click="bigMapBtn(data, num)"
            >
              <!-- 显示地图 -->
              {{ $t("c24") }}
            </el-button>
            <EditableCell
              v-if="item.type !== 'file' && item.name !== 'dldw'"
              class="editableCell"
              :required="item.required === '1' && item.readonly === '0'"
              :disabled="item.isdisabled"
              :field="item.entityFieldName"
              :field-name="item.name"
              :field-id="item.id"
              :input-type="item.type"
              :line-index="num"
              :isBatch="isBatch"
              :min="
                item.valueInterval
                  ? item.valueInterval.min
                  : 0 - Math.pow(10, 18) + 1
              "
              :max="
                item.valueInterval
                  ? item.valueInterval.max
                  : Math.pow(10, 18) - 1
              "
              :schemefieldLength="item.length ? Number(item.length) : 10"
              :precision="item.decimalPlaces ? Number(item.decimalPlaces) : 0"
              :change-events="item.changeEvents"
              :value="
                dataList.length > 0 && dataList[num]
                  ? dataList[num][item.name]
                  : editableCellValue
              "
              :options="dataList[num][`${item.name}Options`]"
              @editValueChange="editValueChange"
              @lookupValue="lookupValue"
              @control="control"
              @remoteSearch="remoteSearch(item.entityFieldName, item, num)"
              ref="EditableCell"
            >
            </EditableCell>
          </li>
        </ul>
      </div>
    </div>
    <div style="padding: 16px 0">
      <el-button type="primary" size="mini" v-show="isShowThree" @click="addul">
        {{ $t("label.batchadd.newbatchpage.button.add") }}
      </el-button>
    </div>
    <slot></slot>

    <!-- 查找/查找多选 -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :fieldId="fieldId"
        :checked="checked"
        :relevant-objid="relevantObjId"
        :relevant-prefix="relevantPrefix"
        @setFieldReltaion="setFieldReltaion"
        @changeSelect="changeSelect"
      />
    </el-dialog>
    <!-- 上传文件弹窗 -->
    <add-files
      ref="addFile"
      :isFromFileCenter="false"
      :dialogAddVisible="dialogAddVisible"
      @addSubmit="addWorkSubmit"
      @close="addWorkClose"
      :originNum="fileInfo.length"
    >
    </add-files>
    <!-- 地图 国内:高德 国外:谷歌 -->
    <el-dialog
      title=""
      :width="mapWidth"
      :top="mapTop"
      :visible.sync="bigMapDialog"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
      custom-class="big-view-box"
    >
      <el-button-group class="btn_map" v-if="mapTop === '6%'">
        <!-- 高德地图 谷歌地图-->
        <!-- 隐藏切换按钮 -->
        <!-- <el-button
          :style="{
            background: isMap ? '#006DCC' : '',
            color: isMap ? '#fff' : '#000',
          }"
          @click="mapBtnChange('gd')"
          >{{ $t("label.gaode.map") }}</el-button
        >
        <el-button
          :style="{
            background: !isMap ? '#006DCC' : '',
            color: !isMap ? '#fff' : '#000',
          }"
          @click="mapBtnChange('gg')"
          >{{ $t("label.google.map") }}</el-button
        > -->
      </el-button-group>
      <!-- 高德/谷歌地图 -->
      <map-group
        ref="mapGroup"
        :mapkey="mapkey"
        :pointValue="clearItemArr.gdPointValue"
        :ggPointValue="clearItemArr.ggPointValue"
        :lineIndex="lineIndex"
        :isInfoMap="isInfoMap"
        :isMap="isMap"
        @chooseLocation="chooseLocation"
        @clearLocation="clearLocation"
        @bigClose="bigClose"
      ></map-group>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRecordType,
  getBatchAdding,
  GetCurrencyInfo,
  save,
  getSelectValue,
  getLookupRelatedFieldValue,
} from "./api";
import INPUTTYPE from "@/config/enumCode/inputType.js";
import EditableCell from "@/components/TablePanel/EditableCell";
import FILEICON from "@/views/ccFileCenter/config/fileIconLibrary";

export default {
  components: {
    EditableCell,
    AddFiles: () => import("@/components/AddFiles/AddFiles.vue"),
    MapGroup: () => import("@/components/Map/index.vue"),
  },
  props: {
    // 页面标记
    pageFlag: {
      type: String,
      default: "",
    },
    // 当前的公海池对象
    currentClientPoolSelectObj: {
      type: Object,
      default: () => ({}),
    },
    prefix: {
      type: String,
      default: "",
    },
    objectApi: {
      type: String,
      default: "",
    },
    recordId: {
      type: String,
      default: "",
    },
    relationFieldId: {
      type: String,
      default: "",
    },
    relatedListFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultIcon: FILEICON.default, // 文件缺省图标
      allIcon: FILEICON, // 文件图标
      // 文件字段信息
      isFromFileCenter: false,
      dialogAddVisible: false,
      editableCellValue: { value: "" },
      isBatch: true,
      fieldId: "",
      relevantObjId: "",
      relevantPrefix: "",
      checked: false,
      isShowOne: false,
      showSearchTable: false,
      val: "",
      objId: "", //记录类型objid
      recordTypeList: [], //记录类型列表
      recordType: "",
      dataList: [],
      datalists: [], //批量增长
      fieldlist: [], //表头信息
      index: "",
      idx: "",
      isShowThree: true,
      isShowFive: false,
      row: "",
      arr: [],
      fieldName: "",
      entityFieldName: "", // 查找字段字段名
      rowIndex: 0, // 查找字段所在行
      flag: true, // 保存数据是否验证通过标识
      lookupValues: [],
      controlContainer: [], //依赖关系
      relationData: {},
      possibilityList: [],
      fileList: [], //保存新增文件字段文件
      fileInfo: { item: "", index: 0, length: 0 },
      fileFiledLists: "", //文件字段信息
      batchLoading: false,
      bigMapDialog: false, //地理定位地图弹窗
      mapkey: "", //地理定位地图key
      lineIndex: 0, //地理定位当行下标
      clearItemArr: {}, //地理定位当行信息
      countryCode: this.$cookies.get("countryCode"),
      mapWidth: "80%",
      mapTop: "6%",
      isMap: true,
      isInfoMap: false,
      lkid: "",
      lkvalue: "",
    };
  },
  methods: {
    // 删除文件
    deleteFile(num, name, index) {
      this.fileList[num][name].splice(index, 1);
      this.$message.success("已移除文件");
    },
    // 接收上传的文件信息
    addWorkSubmit(data) {
      this.dialogAddVisible = false;
      this.fileList[this.fileInfo.index][this.fileInfo.item] = data;
    },
    // 打开上传文件弹窗
    openUpload(item, index) {
      this.fileInfo.item = item.name;
      this.fileInfo.index = index;
      this.fileInfo.length = Number(item.defaultvalue);
      this.$refs.addFile.initPage();
      this.dialogAddVisible = true;
    },
    // 取消上传文件
    addWorkClose() {
      this.dialogAddVisible = false;
    },
    // 重置dataList
    init() {
      // 初始化批量新增数据
      this.val = "";
      this.flag = true;
      this.fieldlist = [];
      this.dataList = [{}, {}, {}, {}, {}];
    },
    //批量新增之获取记录类型
    getRecordType() {
      this.recordType = "";
      this.fieldlist = [];
      this.recordTypeList = [];
      let param = {
        prefix: this.$route.params.prefix
          ? this.$route.params.prefix
          : this.prefix,
      };
      getRecordType(param).then((res) => {
        this.objId = res.data.objid;
        if (res.data.recordTypeList && res.data.recordTypeList.length > 0) {
          this.recordTypeList = res.data.recordTypeList;
          if (
            res.data.recordTypeList !== null &&
            res.data.recordTypeList !== undefined
          ) {
            res.data.recordTypeList.forEach((record) => {
              // 设置默认记录类型
              if (record.isdefault === "true") {
                this.recordType = record.id;
              }
            });
          }
          // 若没有默认记录类型，设置第一个为默认
          if (this.recordType === "") {
            this.recordType =
              this.recordTypeList.length > 0
                ? res.data.recordTypeList[0].id
                : "";
          }
        }
        this.getbatchadding();
        if (this.objId === "opportunity") {
          this.getKNXSelectValue();
        }
      });
    },
    // 获取 阶段-可能性 对应关系
    getKNXSelectValue() {
      getSelectValue({ fieldId: "ffe201100003943eFUc8" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              val: item.codevalue,
              key: item.codekey,
              knx: Number(item.knx),
              forecasttype: item.forecasttype,
            });
          });
          this.possibilityList = options;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 获取币种数据
    getCurrencyInfo() {
      GetCurrencyInfo().then((res) => {
        let currentData = [];
        if (this.$refs.EditableCell) {
          // 币种数据
          res.data.forEach((item) => {
            let obj = {
              value: item.currencycode,
              label: "",
            };
            // 接口返回数据不一致，有的currencyname包含币种缩写，对没有缩写的数据进行拼接
            if(item.currencyname.indexOf(item.currencycode)>-1){
              obj.label=item.currencyname
            }else{
              obj.label=item.currencycode+' - '+item.currencyname
            }
            currentData.push(obj);
          });
          this.$refs.EditableCell.forEach((editableCell) => {
            // 对每条记录的币种字段赋值
            if (
              editableCell.inputType == "select" &&
              editableCell.fieldName == "currency"
            ) {
              this.dataList[editableCell.lineIndex][
                `${editableCell.fieldName}Options`
              ] = currentData;
            }
          });
        }
      });
    },
    //获取批量新增布局信息
    getbatchadding() {
      // 重置列表数据
      if (this.$refs.EditableCell) {
        for (let i = 0; i < this.$refs.EditableCell.length; i++) {
          this.$refs.EditableCell[i].editValue = "";
        }
      }
      var param = {
        recordtypeid: this.recordType,
        objectid: this.objId,
        number: "",
        recordId: this.recordId, //相关列表需要传
        relationFieldId: this.relationFieldId, //相关列表需要传
      };
      getBatchAdding(param).then((res) => {
        this.init();
        //添加地理定位
        this.addAddres(res.data.fieldlist);

        this.fieldlist = res.data.fieldlist;
        let array = res.data.fieldlist;
        let obj = {};
        array &&
          array.map((item) => {
            if (item.type === "FL") {
              obj[item.name] = [];
            }
          });
        obj = JSON.stringify(obj);
        this.fileFiledLists = obj;
        this.fileList = [
          JSON.parse(obj),
          JSON.parse(obj),
          JSON.parse(obj),
          JSON.parse(obj),
          JSON.parse(obj),
        ];
        this.relationData = res.data.relationData;
        this.controlContainer = JSON.parse(res.data.controlContainer);
        if (this.fieldlist) {
          for (let i = 0; i < this.fieldlist.length; i++) {
            // 自动编号禁用
            if (this.fieldlist[i].type && this.fieldlist[i].type === "V") {
              this.$set(this.fieldlist[i], "isdisabled", true);
            } else {
              // 处理其他数据只读（禁用）
              this.$set(
                this.fieldlist[i],
                "isdisabled",
                this.fieldlist[i].readonly === "1"
              );
            }
            // 重置列表数据
            for (let num = 0; num < this.dataList.length; num++) {
              if (
                this.fieldlist[i].lookupObjid === "marketsea" &&
                INPUTTYPE[this.fieldlist[i].type] === "remote-select"
              ) {
                // 公海池字段赋默认值
                this.$set(this.dataList[num], this.fieldlist[i].name, {
                  value: this.currentClientPoolSelectObj.id,
                });
                this.$set(
                  this.dataList[num],
                  `${this.fieldlist[i].name}Options`,
                  [
                    {
                      label: this.currentClientPoolSelectObj.name,
                      value: this.currentClientPoolSelectObj.id,
                    },
                  ]
                );
              } else {
                // 如果布尔值有默认值为"1"或"0"需要做特殊处理，
                if (
                  this.fieldlist[i].defaultvalue &&
                  this.fieldlist[i].type === "B"
                ) {
                  if (this.fieldlist[i].defaultvalue == "1") {
                    this.fieldlist[i].defaultvalue = "true";
                  } else if (this.fieldlist[i].defaultvalue == "0") {
                    this.fieldlist[i].defaultvalue = "false";
                  }
                }
                // 其他字段类型，如果有默认值，优先展示默认值
                this.$set(this.dataList[num], this.fieldlist[i].name, {
                  value: this.fieldlist[i].defaultvalue || "",
                });
                if (
                  INPUTTYPE[this.fieldlist[i].type] === "select" ||
                  INPUTTYPE[this.fieldlist[i].type] === "multi-select"
                ) {
                  this.$set(
                    this.dataList[num],
                    `${this.fieldlist[i].name}Options`,
                    this.fieldlist[i].selectOptions
                  );
                }

                //如果是相关列表才需要回显当前记录查找字段
                if (this.relatedListFlag) {
                  if (
                    this.fieldlist[i].type === "Y" ||
                    this.fieldlist[i].type === "M"
                  ) {
                    if (
                      this.fieldlist[i].name ===
                      this.relationData.relationApiname
                    ) {
                      let option = [
                        {
                          label: this.relationData.relationIdccname,
                          value: this.relationData.relationId,
                        },
                      ];
                      this.dataList[num][this.fieldlist[i].name].value =
                        this.fieldlist[i].defaultvalue;
                      this.$set(
                        this.dataList[num],
                        `${this.fieldlist[i].name}Options`,
                        option
                      );
                    }
                  }
                }
                // 百分数和数字字段字数长度限制
                if (
                  this.fieldlist[i].type === "P" ||
                  INPUTTYPE[this.fieldlist[i].type] === "number"
                ) {
                  let decimal =
                    this.fieldlist[i].decimalPlaces !== undefined &&
                    this.fieldlist[i].decimalPlaces !== null
                      ? 1 /
                        Math.pow(10, Number(this.fieldlist[i].decimalPlaces))
                      : 0;
                  this.fieldlist[i].valueInterval = {
                    min:
                      0 -
                      Math.pow(
                        10,
                        this.fieldlist[i].length -
                          this.fieldlist[i].decimalPlaces -
                          1
                      ) +
                      decimal,
                    max:
                      Math.pow(
                        10,
                        this.fieldlist[i].length -
                          this.fieldlist[i].decimalPlaces -
                          1
                      ) - decimal,
                  };
                }
              }
              // 字段依赖性
              if (this.fieldlist[i].isContrField) {
                this.$set(this.fieldlist[i], "changeEvents", "control");
                this.control(
                  this.fieldlist[i].defaultvalue,
                  num,
                  this.fieldlist[i].id
                );
              }
            }
            this.index = i;
            this.$set(
              this.fieldlist[i],
              "type",
              INPUTTYPE[this.fieldlist[i].type]
            );
          }
        }
        // 依赖关系，绑定事件
        this.controlContainer = JSON.parse(res.data.controlContainer);
        // 查找带值，绑定事件
        if (
          res.data.lookupValueExpression !== undefined &&
          res.data.lookupValueExpression !== "" &&
          res.data.lookupValueExpression !== null
        ) {
          this.setLookupValue(JSON.parse(res.data.lookupValueExpression));
        }
        this.getCurrencyInfo();
        this.$nextTick(() => {
          this.savemessages();
        });
      });
    },
    // 查找带值字段
    setLookupValue(lookupValueExpression) {
      this.lookupValues = [];
      lookupValueExpression.forEach((item) => {
        this.fieldlist.forEach((attr) => {
          if (attr.name === item.expression.split(",")[0].toLowerCase()) {
            attr.changeEvent =
              attr.changeEvent === undefined
                ? "lookupValue"
                : `${attr.changeEvent},lookupValue`;
                // 默认值查找带值
                this.$nextTick(() => {
                  if(attr.defaultvalue){
                  this.getALLLookupValue(lookupValueExpression, attr.name, attr.defaultvalue);
                }
                })
            
          }
        });
        this.lookupValues.push(item);
      });
    },
    // 查找带值
    lookupValue(selectVal, fieldName) {
      this.getALLLookupValue(this.lookupValues, fieldName, selectVal);
    },
    // 查找带值
    getALLLookupValue(lookupValueExpression, fieldName, id) {
      let promiseList = [];
      // 查找字段值为空时不走带值规则
      if (id.toString()) {
        for (let idx in lookupValueExpression) {
          let lookupRule = lookupValueExpression[idx];
          if (lookupRule.expression.split(",")[0].toLowerCase() === fieldName) {
            let fieldJson = [];

            fieldJson.push({
              fieldrelateid: lookupRule.id,
              id: id,
              expression: lookupRule.expression,
              iscover: lookupRule.iscover,
            });
            let param = {
              objId: this.$route.params.prefix
          ? this.$route.params.prefix
          : this.prefix,
              fieldJson: JSON.stringify(fieldJson),
            };

            promiseList.push(
              getLookupRelatedFieldValue(param)
                .then((res) => {
                  let result = {
                    res: res,
                    lookupRule: lookupRule,
                  };
                  return result;
                })
                .catch(() => {})
            );
          }
        }
        // 查找带值合并请求
        Promise.all(promiseList).then((allRes) => {
          allRes.forEach((result) => {
            let res = result.res;
            let lookupRule = result.lookupRule;
            let carryValue = "";
            this.lkid = res.data[0].lkid;
            this.lkvalue = res.data[0].value;
            carryValue =
              res.data && res.data.length > 0 ? res.data[0].value : null;
            // 主对象查找带值
            this.$refs.EditableCell.forEach((editableCell) => {
              // 默认值初始化时，this.rowIndex值为0，也要走
              if (
                (editableCell.lineIndex === this.rowIndex - 1 || this.rowIndex === 0) &&
                editableCell.fieldId === lookupRule.id
              ) {
                if (
                  (editableCell.inputType === "remote-select" ||
                    editableCell.inputType === "remote-multi-select") &&
                  res.data[0].value &&
                  res.data[0].lkid &&
                  res.data[0].value !== "" &&
                  res.data[0].lkid !== ""
                ) {
                  this.dataList[editableCell.lineIndex][
                    `${editableCell.fieldName}Options`
                  ] = [
                    {
                      value: res.data[0].lkid,
                      label: res.data[0].value,
                    },
                  ];
                  editableCell.editValue = res.data[0].lkid;
                } else if (editableCell.inputType === "number") {
                  // 查找带值为数字类型时，为""、null、赋值为undefined
                  if (
                    res.data[0].value === "" ||
                    res.data[0].value === null ||
                    res.data[0].value === undefined
                  ) {
                    editableCell.editValue = undefined;
                  } else {
                    editableCell.editValue = res.data[0].value;
                  }
                } else {
                  editableCell.editValue = carryValue;
                }
              }
              let tableheadname = editableCell.starsClassName.substring(
                0,
                editableCell.starsClassName.length - 1
              );
              if (
                tableheadname == "their_project" &&
                res.data[0].lkid &&
                res.data[0].value
              ) {
                this.dataList[editableCell.lineIndex][
                  `${editableCell.fieldName}Options`
                ] = [
                  {
                    value: res.data[0].lkid,
                    label: res.data[0].value,
                  },
                ];

                editableCell.editValue = res.data[0].lkid;
              }
            });
          });
        });
      }
    },
    // 依赖字段
    control(selectVal, lineIndex, fieldId) {
      // 查找对应规则
      let control = this.controlContainer.find((item) => {
        return item.controlfield === fieldId;
      });

      if (control !== undefined) {
        // 先将被控字段选项清空,循环后有对应选项再进行赋值
        control.controlVals.forEach((vals) => {
          vals.dependFields.forEach((dependField) => {
            this.$nextTick(() => {
              this.$refs.EditableCell.forEach((editableCell) => {
                if (
                  editableCell.lineIndex === lineIndex &&
                  editableCell.fieldId === dependField.dependfieldid
                ) {
                  this.$set(
                    this.dataList[lineIndex],
                    [`${editableCell.fieldName}Options`],
                    []
                  );
                  // if(editableCell.fieldName && editableCell.fieldName.slice(editableCell.fieldName.length - 2) === "01"){
                  //   let attrField = editableCell.fieldName.slice(0,editableCell.fieldName.length - 2)
                  //   this.dataList[lineIndex][`${attrField}02`].value = ''
                  //   this.dataList[lineIndex][`${attrField}04`].value = ''
                  // }
                }
              });
            });
          });
        });

        // 查找当前值是否有控制规则
        let controlCondition = control.controlVals.find((item) => {
          return item.val === selectVal;
        });
        if (controlCondition === undefined) {
          control.controlVals.forEach((vals) => {
            // 控制值
            vals.dependFields.forEach((dependField) => {
              this.$nextTick(() => {
                this.$refs.EditableCell.forEach((editableCell) => {
                  if (
                    editableCell.lineIndex === lineIndex &&
                    editableCell.fieldId === dependField.dependfieldid
                  ) {
                    editableCell.editValue = "";
                    this.control("", lineIndex, dependField.dependfieldid);
                  }
                });
              });
            });
          });
        }

        // 控制条件
        control.controlVals.forEach((vals) => {
          if (vals.val === selectVal) {
            // 控制值
            vals.dependFields.forEach((dependField) => {
              this.$nextTick(() => {
                this.$refs.EditableCell.forEach((editableCell) => {
                  if (
                    editableCell.lineIndex === lineIndex &&
                    editableCell.fieldId === dependField.dependfieldid
                  ) {
                    let options = [];
                    dependField.vals.forEach((element) => {
                      options.push({
                        label: element.deflangval,
                        value: element.val,
                      });
                    });
                    this.$set(
                      this.dataList[lineIndex],
                      [`${editableCell.fieldName}Options`],
                      options
                    );
                    // 若当前选中值不在选项列表中，则清空
                    let option = options.find((item) => {
                      return item.value === editableCell.editValue;
                    });
                    if (option === undefined) {
                      // 清空被控字段值并递归
                      editableCell.editValue = "";
                      this.control("", lineIndex, dependField.dependfieldid);
                    }
                  }
                });
              });
            });
          }
        });
      }
    },
    // 设置查找筛选条件
    setFieldReltaion(filterFieldIds) {
      let values = [""];
      for (let reltaion in filterFieldIds) {
        this.$refs.EditableCell.forEach((editableCell) => {
          if (
            editableCell.lineIndex === this.rowIndex - 1 &&
            editableCell.fieldId === filterFieldIds[reltaion].fieldid
          ) {
            if (Array.isArray(editableCell.editValue)) {
              values.push(editableCell.editValue.join(";"));
            } else {
              values.push(editableCell.editValue);
            }
          }
        });
      }
      this.$refs.searchTable.filterConditionVals = values;
    },
    //获取保存信息
    savemessages() {
      let datalists = [];
      for (let line = 0; line < this.dataList.length; line++) {
        let obj = {};
        this.$refs.EditableCell &&
          this.$refs.EditableCell.forEach((editableCell) => {
            if (editableCell.lineIndex === line) {
              obj[editableCell.fieldName] = editableCell.editValue;
            }
          });
        let fileObj = this.fileList;
        for (let key in fileObj[line]) {
          obj[key] = "";
          fileObj[line][key].map((item) => {
            let str = item.id + ",";
            obj[key] += str;
          });
          obj[key] = obj[key].substring(0, obj[key].length - 1);
        }
        obj.recordtype = this.recordType;
        datalists.push(obj);
      }
      var crr = [];
      for (let i = 0; i < datalists.length; i++) {
        if (crr.indexOf(datalists[i]) == -1) {
          crr.push(datalists[i]);
        }
      }
      this.datalists = crr;
    },
    //批量新增保存
    savedata() {
      this.flag = true;
      this.savemessages();
      for (let j = 0; j < this.fieldlist.length; j++) {
        if (
          this.fieldlist[j].required === "1" &&
          this.fieldlist[j].readonly === "0"
        ) {
          for (let i = 0; i < this.datalists.length; i++) {
            Object.keys(this.datalists[i]).forEach((item) => {
              // 必填、非只读字段 字段值为空时给字段不能为空提示
              // 字段为空有undefined、null、空字符串、空数组
              if (
                this.fieldlist[j].required === "1" &&
                this.fieldlist[j].readonly === "0" &&
                item == this.fieldlist[j].name &&
                (this.datalists[i][item] === undefined ||
                  this.datalists[i][item] === null ||
                  this.datalists[i][item] === "" ||
                  this.datalists[i][item].length === 0)
              ) {
                this.$message.warning(
                  this.$i18n.t("label.batchadd.newbatchpage.save.emptytext")
                );
                this.flag = false;
              }
            });
            //如果批量新增弹窗里没有查找字段，加上
            if (
              this.relatedListFlag &&
              this.relationData.relationApiname &&
              this.fieldlist[j].name !== this.relationData.relationApiname
            ) {
              this.$set(
                this.datalists[i],
                this.relationData.relationApiname,
                this.relationData.relationId
              );
            }
          }
        }
      }
      for (let i = 0; i < this.datalists.length; i++) {
        // 开始、结束时间都存在时添加判断，开始日期不得大于结束日期
        if (this.datalists[i].start_date && this.datalists[i].end_date) {
          let startDate = new Date(this.datalists[i].start_date);
          let endDate = new Date(this.datalists[i].end_date);
          if (startDate.getTime() > endDate.getTime()) {
            this.$message.warning(this.$i18n.t("label.weixin.end.later.start"));
            this.batchLoading = false;
            this.$emit("getBatchLoading", false);
            this.flag = false;
          }
        }
        // 项目管理系统下,项目计划--合同金额、费用金额添加验证
        if (
          (this.datalists[i].budget_cost &&
            this.datalists[i].budget_cost.toString().length > 10) ||
          (this.datalists[i].contract_amount &&
            this.datalists[i].contract_amount.toString().length > 10)
        ) {
          // 合同金额最多输入10位
          this.$message.warning(
            this.$i18n.t(
              "label.projectManagement.the.maximum.contract.amount.is.10.digits"
            )
          );
          this.flag = false;
        }
        if (
          this.datalists[i].contract_amount_cost &&
          this.datalists[i].contract_amount_cost.toString().length > 10
        ) {
          // 费用最多输入10位
          this.$message.warning(
            this.$i18n.t(
              "label.projectManagement.the.maximum.number.of.fees.is.10"
            )
          );
          this.flag = false;
        }
        //加经纬度保存
        for (let j = 0; j < this.dataList.length; j++) {
          if (i === j) {
            //国内
            if (
              this.dataList[j].gdPointValue !== [] &&
              this.dataList[j].gdPointValue
            ) {
              this.datalists[i][this.dldwName + "longitude"] =
                this.dataList[j].gdPointValue[0].toString();
              this.datalists[i][this.dldwName + "latitude"] =
                this.dataList[j].gdPointValue[1].toString();
            }
            //国外
            if (this.dataList[j].ggPointValue !== undefined) {
              this.datalists[i][this.dldwName + "longitude"] =
                this.dataList[j].ggPointValue.lat.toString();
              this.datalists[i][this.dldwName + "latitude"] =
                this.dataList[j].ggPointValue.lng.toString();
            }
          }
        }
      }
      var param = {
        objectApi: this.$route.params.objectApi
          ? this.$route.params.objectApi
          : this.objectApi,
        data: JSON.stringify(this.datalists),
        marketsea: this.pageFlag === "clientPool" ? "true" : "false", //true是公海池新建记录，false不是
      };
      if (this.flag) {
        this.batchLoading = true;
        this.$emit("getBatchLoading", true);
        save(param)
          .then((res) => {
            //批量新增保存验证错误信息提示
            res.data.forEach((data) => {
              if (data.errormessage) {
                this.$message.error(data.errormessage);
                return Promise.reject("关闭");
              }
            });
            this.batchLoading = false;
            this.$emit("getBatchLoading", false);
            this.close();
            this.$emit("refresh");
            this.$emit("closeBatchAddDialog", "save");
          })
          .catch(() => {
            this.batchLoading = false;
            this.$emit("getBatchLoading", false);
            return Promise.reject("关闭");
          });
      }
    },
    close() {
      this.getRecordType();
      this.init();
      this.fieldlist = [];
      this.val = "";
    },
    // 添加一行
    addul() {
      if (this.fieldlist != "") {
        this.dataList.push({});
        this.fileList.push(JSON.parse(this.fileFiledLists));
        for (let i = 0; i < this.fieldlist.length; i++) {
          // 重置列表数据
          for (
            let num = this.dataList.length;
            num <= this.dataList.length;
            num++
          ) {
            //marketsea(公海池字段)remote-select(查找字段)
            if (
              this.fieldlist[i].type === "remote-select" &&
              this.fieldlist[i].lookupObjid === "marketsea"
            ) {
              // 公海池字段赋默认值
              this.$set(this.dataList[num - 1], this.fieldlist[i].name, {
                value: this.currentClientPoolSelectObj.id,
              });
              this.$set(
                this.dataList[num - 1],
                `${this.fieldlist[i].name}Options`,
                [
                  {
                    label: this.currentClientPoolSelectObj.name,
                    value: this.currentClientPoolSelectObj.id,
                  },
                ]
              );
            } else {
              // 其他字段，如果有默认值，优先展示默认值
              this.$set(this.dataList[num - 1], this.fieldlist[i].name, {
                value: this.fieldlist[i].defaultvalue || "",
              });
              if (
                this.fieldlist[i].type === "select" ||
                this.fieldlist[i].type === "multi-select"
              ) {
                this.$set(
                  this.dataList[num - 1],
                  `${this.fieldlist[i].name}Options`,
                  this.fieldlist[i].selectOptions
                );
              } //相关列表下查找字段赋值
              else if (
                this.fieldlist[i].type === "remote-select" &&
                this.relatedListFlag
              ) {
                if (
                  this.fieldlist[i].name === this.relationData.relationApiname
                ) {
                  let option = [
                    {
                      label: this.relationData.relationIdccname,
                      value: this.relationData.relationId,
                    },
                  ];
                  this.dataList[num - 1][this.fieldlist[i].name].value =
                    this.fieldlist[i].defaultvalue;
                  this.$set(
                    this.dataList[num - 1],
                    `${this.fieldlist[i].name}Options`,
                    option
                  );
                }
                if (this.fieldlist[i].name === "their_project") {
                  let option = [
                    {
                      label: this.lkvalue,
                      value: this.lkid,
                    },
                  ];
                  this.dataList[num - 1][this.fieldlist[i].name].value =
                    this.lkid;
                  this.$set(
                    this.dataList[num - 1],
                    `${this.fieldlist[i].name}Options`,
                    option
                  );
                  this.dataList[num - 1], "this.dataList[num - 1]";
                }
              }
            }
            // 字段依赖性
            if (this.fieldlist[i].isContrField) {
              this.$set(this.fieldlist[i], "changeEvents", "control");
              this.control(
                this.fieldlist[i].defaultvalue,
                num - 1,
                this.fieldlist[i].id
              );
            }
          }
        }
      }
      if (this.dataList.length >= 30) {
        this.isShowThree = false;
      }
    },
    // 编辑值改变
    editValueChange(val, lineIndex, field) {
      this.dataList[lineIndex][field] = { value: val };

      // 业务机会对象可能性随阶段变化显示对应的值
      if (this.objId === "opportunity" && field === "jieduan") {
        let knx = this.possibilityList.find((item) => {
          return item.val === val;
        });
        // 找是否存在可能性和预测类别字段
        if (knx && "knx" in this.dataList[lineIndex]) {
          this.$set(this.dataList[lineIndex], "knx", {
            value: knx.knx.toString(),
          });
        }
        if (knx && "forecasttype" in this.dataList[lineIndex]) {
          this.$set(this.dataList[lineIndex], "forecasttype", {
            value: knx.forecasttype,
          });
        }
      }
    },
    /**
     * 查找/查找多选
     * fieldName: 字段名称
     * item:当前数据
     * num:所在行数
     */
    remoteSearch(entityFieldName, item, num) {
      // this.entityFieldName = entityFieldName
      this.fieldName = item.name;
      this.rowIndex = num + 1;

      this.fieldId = item.id;
      this.relevantObjId = item.lookupObjid;
      this.idx = num + 1;
      this.relevantPrefix = item.lookupObj;
      this.showSearchTable = true;
      this.checked = item.type === "remote-multi-select" ? true : false;

      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    // 改变查找/查找多选的值
    changeSelect(row) {
      // rowIndex:当前行数
      // entityFieldName:字段名称
      let options = [];
      let optionValue = [];
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
          optionValue.push(ele.id);
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
        optionValue = row.data.id.toString();
      }

      let value = {
        value: optionValue,
      };
      // 当前查找值赋值
      this.$set(this.dataList[this.rowIndex - 1], this.fieldName, value);
      // 当前查找赋值选项列表
      this.$set(
        this.dataList[this.rowIndex - 1],
        `${this.fieldName}Options`,
        options
      );
      this.showSearchTable = false;
      // 若有查找带值，将值带入
      this.getALLLookupValue(this.lookupValues, this.fieldName, optionValue);
    },
    // 删除一行
    deleteUl(num) {
      if (this.fieldlist != "" && this.dataList.length > 1) {
        this.dataList.splice(num, 1);
        this.fileList.splice(num, 1);
      }
      if (this.dataList.length < 30) {
        this.isShowThree = true;
      }
    },
    //地图切换
    mapBtnChange(e) {
      if (e === "gd") {
        this.isMap = true;
        this.mapFun();
      } else {
        this.isMap = false;
        this.mapFun();
      }
    },
    bigMapBtn(item, num) {
      this.lineIndex = num;
      this.clearItemArr = item;
      this.bigMapDialog = true;
      if (this.clearItemArr[this.dldwName + "00"].value) {
        if (
          this.clearItemArr[this.dldwName + "00"].value ===
            this.$i18n.t("label.china") ||
          this.clearItemArr[this.dldwName + "00"].value ===
            this.$i18n.t("label.the.peoples.republic.of.china") ||
          this.clearItemArr[this.dldwName + "00"].value === "China"
        ) {
          this.isMap = true;
          this.mapFun();
        } else {
          this.isMap = false;
          this.mapFun();
        }
      } else {
        if (this.countryCode === "CN") {
          this.isMap = true;
          this.mapFun();
        } else {
          this.isMap = false;
          this.mapFun();
        }
      }
    },
    //地图初始化
    mapFun() {
      if (this.isMap) {
        this.mapkey = window.Glod['AMAP_KEY']
      } else {
        this.mapkey = "AIzaSyCyWXGrhpOd1py9eUCpzRAzbDjWtXQNqGU";
      }
      this.$nextTick(() => {
        this.$refs.mapGroup.init();
        this.$refs.mapGroup.searchKey = "";
        this.$refs.mapGroup.searchList = [];
      });
    },
    //关闭地图
    bigClose() {
      this.bigMapDialog = false;
    },
    //地图返回信息
    chooseLocation(allAdd, obj) {
      this.bigMapDialog = false;
      //CN 高德地图 ，海外谷歌
      if (this.isMap) {
        this.$nextTick(() => {
          if (allAdd.addressComponent.country) {
            this.fieldlist.forEach((val) => {
              if (val.name === this.dldwName + "00") {
                this.control(
                  allAdd.addressComponent.country,
                  this.lineIndex,
                  val.id
                );
              }
            });
          }
          this.dataList[this.lineIndex].gdPointValue = obj ? obj : [];
          this.dataList[this.lineIndex][this.dldwName + "00"].value =
            allAdd.addressComponent.country;
          this.dataList[this.lineIndex][this.dldwName + "01"].value =
            allAdd.addressComponent.province;
          this.dataList[this.lineIndex][this.dldwName + "02"].value =
            allAdd.addressComponent.city;
          this.dataList[this.lineIndex][this.dldwName + "04"].value =
            allAdd.formattedAddress;
          let longitude = {
            value: obj[0],
          };
          let latitude = {
            value: obj[1],
          };
          this.dataList[this.lineIndex][this.dldwName + "longitude"] =
            longitude;
          this.dataList[this.lineIndex][this.dldwName + "latitude"] = latitude;
        });
      } else {
        this.$nextTick(() => {
          this.dataList[this.lineIndex].ggPointValue = allAdd.geometry
            ? allAdd.geometry.location
            : {};
          let allAddLength = "";
          allAdd.address_components.forEach((val, index) => {
            //如果是美国/加拿大/澳大利亚/墨西哥/英国/新加坡/法国/德国/的话找对应下标赋值
            if (
              val.short_name === "US" ||
              val.short_name === "CA" ||
              val.short_name === "AU" ||
              val.short_name === "MX" ||
              val.short_name === "GB" ||
              val.short_name === "SG" ||
              val.short_name === "FR" ||
              val.short_name === "DE"
            ) {
              allAddLength = index;
            }
          });
          this.fieldlist.forEach((val) => {
            if (val.name === this.dldwName + "00") {
              this.control(
                allAdd.address_components[allAddLength].long_name,
                this.lineIndex,
                val.id
              );
            }
          });
          this.dataList[this.lineIndex][this.dldwName + "00"].value =
            allAdd.address_components[allAddLength].long_name;
          this.dataList[this.lineIndex][this.dldwName + "01"].value =
            allAdd.address_components[allAddLength - 1].long_name;
          this.dataList[this.lineIndex][this.dldwName + "02"].value =
            allAdd.address_components[allAddLength - 2].long_name;
          this.dataList[this.lineIndex][this.dldwName + "04"].value =
            allAdd.formatted_address;
        });
      }
    },
    //清空地图信息
    clearLocation() {
      this.bigMapDialog = false;
      this.dataList[this.lineIndex].gdPointValue = [];
      this.dataList[this.lineIndex].ggPointValue = {};
      this.dataList[this.lineIndex][this.dldwName + "00"].value = "";
      this.dataList[this.lineIndex][this.dldwName + "01"].value = "";
      this.dataList[this.lineIndex][this.dldwName + "02"].value = "";
      this.dataList[this.lineIndex][this.dldwName + "04"].value = "";
    },
    //添加地理定位
    addAddres(arr) {
      let newAdress = {
        label: this.$i18n.t("LT"), //地理定位
        name: "dldw", //地理定位
        id: "1",
      };
      let countryIndex = "";
      let isAddress = false;
      arr &&
        arr.forEach((val, index) => {
          if (
            val.name &&
            val.name.slice(val.name.length - 2) === "00" &&
            val.type === "L"
          ) {
            isAddress = true;
            countryIndex = index;
            this.dldwName = val.name.slice(0, val.name.length - 2);
          }
        });
      if (isAddress) {
        arr.splice(countryIndex, 0, newAdress);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
}
.buttonWrapper {
  position: relative;

  .boxCard {
    width: 120px;
    position: absolute;
    right: 0;
    z-index: 10;

    ::v-deep .el-card__body {
      padding: 12px;
    }

    .textItem {
      font-size: 12px;
      cursor: pointer;
    }
  }

  .createBtn {
    height: 30px;
    margin-right: 10px;
    background: #006dcc;
  }

  .top-btns {
    border: 1px solid #c8c6c6;
    border-radius: 3px;
    height: 30px;
    line-height: 30px;
    background: #ffff;
    li {
      font-size: 12px;

      .btnName {
        padding: 0 12px;
        height: 100%;
        display: inline-block;
        cursor: pointer;

        &:hover {
          color: #006dcc;
        }
      }

      .dividingLine {
        font-size: 16px;
        color: #c8c6c6;
      }

      .icon {
        font-size: 12px;
        color: #979797;
        padding-right: 12px;
      }
    }
  }

  .line-two-btn {
    .btn {
      padding: 0;
      height: 30px;
      border: 1px solid #c8c6c6;
    }

    img {
      width: 18px;
      height: 18px;
    }

    .group-btn {
      margin-top: -15px;
    }

    .in-left {
      margin-left: 10px;
    }

    .in-right {
      margin-right: 10px;
    }
  }
}
.title {
  height: 53px;
  line-height: 53px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  color: rgba(109, 114, 120, 1);
  font-size: 18px;
}
.btnp {
  font-weight: bold;
  font-size: 17px;
}
.btnx {
  font-size: 16px;
}
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
.listulFir {
  height: 40px;
  min-width: 100%;
  color: #2a2a2a;
  border: 1px solid #d8d8d8;
  border-right: none;
  border-left: none;
  display: inline-flex;
  background: #fafaf9;
}
.listulSec:nth-of-type(2n) {
  min-height: 40px;
  display: inline-flex;
  min-width: 100%;
  border-bottom: 1px solid #d8d8d8;
}
.listulSec:nth-of-type(2n + 1) {
  min-height: 40px;
  display: inline-flex;
  background: white;
  min-width: 100%;
  border-bottom: 1px solid #d8d8d8;
}
.firstLi {
  border-right: 1px solid #d8d8d8;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2a2a2a;
}

.delBtn {
  cursor: pointer;
  color: #006dcc;
}

.secondLi {
  border-right: 1px solid#D8D8D8;
  text-align: center;
  width: 180px;
  display: flex;
  justify-content: center;
  .uploadFile {
    width: 100%;
    padding-top: 12px;
    color: #b6b6b6;
    cursor: pointer;
    text-align: left;
    padding-left: 10px;
  }
  .fileContent {
    width: 90%;
    height: 93%;
    color: #b6b6b6;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 10px;
    padding-bottom: 0px;
    margin-top: 5px;
    .fileItem {
      width: 150px;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .itemFir {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .itemTitle {
          display: flex;
          color: #6d7f9a;
          align-items: center;
          .name {
            max-width: 83px;
            word-wrap: break-word;
            height: 16px;
            overflow: hidden;
          }
        }
      }
      .itemSec {
        cursor: pointer;
        margin-top: 5px;
        text-align: left;
        height: 20px;
        line-height: 20px;
      }
    }
  }
  ::v-deep .big_map {
    padding: 12px 10px;
    line-height: 1px;
    margin-top: 7px;
    width: 80px;
    span {
      font-size: 12px !important;
    }
  }
  ::v-deep .el-input {
    height: 30px;
    width: 100%;
    text-align: center;
  }
  ::v-deep .el-input__inner {
    height: 30px;
    width: 100%;
    padding: 0 15px;
  }
  ::v-deep .el-button--mini {
    padding: 7px 7px;
  }
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: rgba(51, 51, 51, 1);
  }
  // ::v-deep .el-input__suffix-inner {
  //   height:30px !important;
  //   line-height:30px !important;
  // }
  .al {
    width: auto;
    overflow: auto;
    max-height: 250px;
    border: 1px solid #d8d8d8;
    border-top: none;
    border-bottom: none;
  }

  .requiredIdentification {
    color: red;
    position: absolute;
    left: -1px;
    top: -3px;
  }

  .my-autocomplete {
    li {
      line-height: normal;
      padding: 7px;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .addr {
        font-size: 12px;
        color: #b4b4b4;
      }

      .highlighted .addr {
        color: #ddd;
      }
    }
  }

  .editableCell {
    height: 40px;
  }
  .xzjllx {
    margin-bottom: 20px;
    position: relative;
  }
  .tianjia {
    margin-top: 20px;
    cursor: pointer;
    width: 90%;
    width: 32px;
    margin-left: 16px;
  }
  ::v-deep .el-table .cell {
    padding-right: 2px;
    padding-left: 2px;
  }
  ::v-deep .el-table--mini td {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  ::v-deep .editableCell {
    display: flex;
    align-items: center;
    padding: 0 5px; //wj
  }
  ::v-deep .el-input-number {
    line-height: 30px;
  }
}
::v-deep .big-view-box {
  .el-dialog__header {
    padding: 0 !important;
    .el-dialog__headerbtn {
      display: none;
    }
  }
  .el-dialog__body {
    padding: 0 !important;
    .bm-view-big {
      width: 100%;
      height: 550px;
    }
    .btn_map {
      position: absolute;
      top: 16px;
      right: 90px;
      z-index: 9;
      button {
        height: 44px;
      }
    }
  }
}
::v-deep .el-input__suffix-inner {
  height: 30px !important;
  line-height: 30px !important;
}
</style>
